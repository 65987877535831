// PattrolAuthentication
// Componente INTERNO a esta biblioteca, não deve ser exportado para fora desta biblioteca
// Ele deverá ser o primeiro componente numa "hierarquia" de autenticação e autorização
// O objetivo é garantir que o usuario tenha sido autenticado no Microsoft 365 antes de acesso aos "subordinados"
import React from 'react'
import { PublicClientApplication, InteractionType } from '@azure/msal-browser'
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react'

import { msalConfig, loginRequest } from './PattrolMsalConfig'

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>
}

function PattrolAuthentication(props) {
  const msalInstance = new PublicClientApplication(msalConfig)

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        {props.children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default PattrolAuthentication
