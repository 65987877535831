export const msalConfig = {
  auth: {
    clientId: '97b03e42-be0a-4f1e-a539-f76811b36d12',
    authority:
      'https://login.microsoftonline.com/b1790651-6abd-4663-a594-a11210fcab9f', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri:
      process.env.NODE_ENV === 'production'
        ? `${window.location.href}`
        : 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

export const loginRequest = {
  scopes: ['User.Read']
}
