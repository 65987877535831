import { createContext } from 'react'

// cria o contexto atraves do qual serao disponibilizados os dados do usuario
export const PattrolUserContext = createContext({
  account: {},
  token: {},
  permissions: {},
  getApi: () => {},
  getApiBlob: () => {},
  postApi: () => {},
  putApi: () => {},
  deleteApi: () => {}
})
