import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import React from 'react'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 5px 10px #0000004d',
    fontSize: 15,
    position: 'absolute',
    left: `calc(100% + 60px)`,
    top: '-25px',
    whiteSpace: 'nowrap',
    background: '#fff',
    padding: '10px 12px',
    borderRadius: '4px'
  }
}))

const MenuCardItem = ({
  iconName,
  Text,
  onClick,
  open,
  to,
  style,
  divider = false,
  hasSubMenu = false,
  isExpanded = false
}) => {
  const styles = {
    icon: {
      fontSize: '25px',
      color: 'black'
    },
    listItemButton: {
      borderRadius: '10px',
      width: '50px',
      padding: '8px 10px 8px 13px',
      overflow: 'hidden'
    },
    text: {
      padding: 0
    },
    divider: {
      width: '3px',
      height: '30px',
      backgroundColor: '#8C1B1E',
      marginRight: '10px',
      marginTop: '5px'
    },
    subMenuIcon: {
      marginLeft: 'auto',
      transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
      transition: 'transform 0.2s',
      color: 'gray'
    }
  }

  return (
    <ListItem disablePadding>
      {!open && (
        <LightTooltip title={Text} placement='left' style={style}>
          <ListItemButton onClick={onClick} sx={styles.listItemButton} to={to}>
            <ListItemIcon>
              <Icon>{iconName}</Icon>
            </ListItemIcon>
            <ListItemText primary={Text} />
            {hasSubMenu && (
              <Icon style={styles.subMenuIcon}>chevron_right</Icon>
            )}
          </ListItemButton>
        </LightTooltip>
      )}
      {open && (
        <ListItemButton
          onClick={onClick}
          sx={styles.listItemButton}
          to={to}
          style={style}
        >
          <span style={divider ? styles.divider : {}} />
          <ListItemIcon>
            <Icon>{iconName}</Icon>
          </ListItemIcon>
          <ListItemText primary={Text} sx={styles.text} />
          {hasSubMenu && <Icon style={styles.subMenuIcon}>chevron_right</Icon>}
        </ListItemButton>
      )}
    </ListItem>
  )
}

export default MenuCardItem
