// import React, { useContext } from 'react'
import React from 'react'
// import { menuItems } from './menuItemsList'
// import MenuItems from './MenuItems'
// import { PattrolUserContext } from '../PattrolUserContext/PattrolUserContext'
// import { PattrolPermissionLevels } from '../PattrolUserContext/PattrolPermissionLevels'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { logo } from '../../assets/image64'

const Header = ({ breadcrumbs, position }) => {
  // const userContext = useContext(PattrolUserContext)
  // let menuItems = []

  // // home menu
  // menuItems = [...menuItems, { title: 'Home', url: '/hr' }]
  // // hr menu
  // let hrMenu = { title: 'RH', url: '/hr', submenu: [] }
  // if (userContext.permissions) {
  //   if (
  //     userContext.permissions.includes(PattrolPermissionLevels.hrSuperAdmin) ||
  //     userContext.permissions.includes(PattrolPermissionLevels.hrAdmin) ||
  //     userContext.permissions.includes(PattrolPermissionLevels.hrView)
  //   ) {
  //     hrMenu.submenu = [
  //       ...hrMenu.submenu,
  //       { title: 'Colaborador', url: '/hr/colaborador' }
  //     ]
  //   }
  //   if (
  //     userContext.permissions.includes(PattrolPermissionLevels.hrSuperAdmin)
  //   ) {
  //     hrMenu.submenu = [
  //       ...hrMenu.submenu,
  //       { title: 'Filial', url: '/hr/tables/filial' }
  //     ]
  //     hrMenu.submenu = [
  //       ...hrMenu.submenu,
  //       { title: 'Função', url: '/hr/tables/funcao' }
  //     ]
  //     hrMenu.submenu = [
  //       ...hrMenu.submenu,
  //       { title: 'Nível', url: '/hr/tables/nivel' }
  //     ]
  //     hrMenu.submenu = [
  //       ...hrMenu.submenu,
  //       { title: 'Salário', url: '/hr/tables/salario' }
  //     ]
  //   }
  // }
  // hrMenu.submenu = [
  //   ...hrMenu.submenu,
  //   { title: 'Organograma', url: '/hr/organograma' }
  // ]
  // menuItems = [...menuItems, hrMenu]

  const styles = {
    header: {
      display: 'flex',
      position: position || 'fixed',
      alignItems: 'center',
      background: '#EDEDED',
      borderBottom: '3px solid #D1D1D1',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 999
    },
    logo: {
      width: '130px',
      height: '55px',
      marginLeft: '20px',
      marginTop: '2px'
    },
    breadcrumbsStyle: {
      marginLeft: '25px',
      marginTop: '5px'
    },
    breadcrumbLink: {
      transition: '0.1s',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderRadius: '5px' // ajuste do raio da borda
      },
      fontSize: '15px',
      padding: '5px'
    },
    labelStyle: {
      fontSize: '15px'
    }
  }

  return (
    <header style={styles.header}>
      <a href='/'>
        <img src={logo} style={styles.logo} alt='Logo' />
      </a>
      {/* Breadcrumbs */}
      <Breadcrumbs
        aria-label='breadcrumb'
        style={styles.breadcrumbsStyle}
        separator='›'
      >
        {breadcrumbs &&
          breadcrumbs.map(({ label, path }, index) => {
            const isLast = index === breadcrumbs.length - 1
            return isLast ? (
              <Typography
                color='textPrimary'
                key={index}
                sx={styles.labelStyle}
              >
                {label}
              </Typography>
            ) : (
              <Link
                color='inherit'
                href={path}
                key={index}
                sx={styles.breadcrumbLink}
              >
                {label}
              </Link>
            )
          })}
      </Breadcrumbs>
    </header>
  )
}

export default Header
