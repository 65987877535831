import React from 'react'

const styles = {
  button_primary: {
    padding: '16px',
    borderRadius: '5px',
    backgroundColor: '#2B3990',
    fontFamily: 'RotisSemiSansStd',
    color: 'white',
    width: 'auto',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  }
}

const ButtonPrimary = ({ onClickHandle, label }) => {
  return (
    <button style={styles.button_primary} onClick={onClickHandle}>
      {label}
    </button>
  )
}

export default ButtonPrimary
