import ButtonPrimary from './ButtonPrimary'
import ContactTable from './ContactTable'
import SectionTitle from './SectionTitle'
import React from 'react'

const styles = {
  container: {
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  space: {
    height: '46px'
  }
}

function ContactSection() {
  const onClickHandle = () => {
    const url =
      'https://pattrol.sharepoint.com/:l:/s/EngenhariaDigital/FNGITYwwvwBKiwoseweQfaABzFnazC0otxCQO5A1aZUqDA?e=bSyVaS'
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div style={styles.container}>
      <SectionTitle title='Contatos' />
      <ContactTable />
      <div style={styles.space} />
      <ButtonPrimary label='Lista de contatos' onClickHandle={onClickHandle} />
    </div>
  )
}

export default ContactSection
