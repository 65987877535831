import React, { useEffect } from 'react'
import Header from './Header'
import MenuCard from '../../MenuCard/MenuCard'
import PattrolAuthentication from '../PattrolUserContext/PattrolAuthentication'
import PattrolAuthorization from '../PattrolUserContext/PattrolAuthorization'

const styles = {
  content: {
    marginLeft: '55px',
    marginTop: '70px',
    padding: '0px 30px'
  }
}

function loadMaterialIcons() {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons'
  document.head.appendChild(link)
}

export function PattrolLayout({
  children,
  permissionsAllowed,
  breadcrumbs,
  position
}) {
  useEffect(() => {
    loadMaterialIcons()
  }, [])

  return (
    <PattrolAuthentication>
      <PattrolAuthorization permissionsAllowed={permissionsAllowed}>
        <Header breadcrumbs={breadcrumbs} position={position} />
        <MenuCard />
        <div style={styles.content}>{children}</div>
      </PattrolAuthorization>
    </PattrolAuthentication>
  )
}
