import React from 'react'

import { PattrolLayout } from './components'
import HomePage from './components/HomePage/HomePage'
import './fonts.css'
import ReactDOM from 'react-dom/client'
export * from './components'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <PattrolLayout>
      <HomePage />
    </PattrolLayout>
  </React.StrictMode>
)
