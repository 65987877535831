import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import assEngDigital from '../assets/assEngDigital64'
import MenuCardItem from './MenuCardItem'
import Icon from '@mui/material/Icon'

import { PattrolUserContext } from '../components/PattrolUserContext'

const drawerWidth = 270

const styles = {
  logo: {
    width: '120px',
    height: '135px',
    position: 'absolute',
    bottom: 40,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  textMenu: {
    fontSize: '21px',
    marginLeft: '10px',
    marginInlineEnd: '140px'
  }
}
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: '55px',
  alignItems: 'center',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
  borderRight: '3px solid #D1D1D1'
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px 0px 8px',
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'relative',
  paddingTop: '20px',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      top: 65,
      backgroundColor: '#EDEDED'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      top: 65,
      backgroundColor: '#EDEDED'
    }
  })
}))

const MenuCard = () => {
  const [open, setOpen] = useState(false)
  const [menuItems, setMenuItems] = useState([])
  const [expandedMenus, setExpandedMenus] = useState({})
  const userContext = useContext(PattrolUserContext)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const toggleSubMenu = (index) => {
    setExpandedMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userContext) {
        try {
          const result = await userContext.getApi(
            `usermenu/${userContext.token.account.username}`,
            userContext.token
          )
          setMenuItems(result || [])
        } catch (error) {
          console.error('Error fetching menu items:', error)
        }
      }
    }

    fetchData()
  }, [userContext])

  // const isSubMenuOpen = (index) => {
  //     const topLevelItem = menuItems[index];
  //     if (location.pathname === topLevelItem.to) {
  //         return true;
  //     }
  //     return topLevelItem.subItems.some(subItem => location.pathname === subItem.to);
  // };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <span
            style={{ ...styles.textMenu, display: open ? 'block' : 'none' }}
          >
            Menu
          </span>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <Icon>arrow_back_ios</Icon>
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <Icon>menu</Icon>
            </IconButton>
          )}
        </DrawerHeader>

        <Divider />
        <List style={{ overflowY: 'auto', flexGrow: 1 }}>
          {menuItems.map((item, index) => (
            <div key={index}>
              <MenuCardItem
                iconName={item.iconName}
                Text={item.text}
                open={open}
                to={item.to}
                hasSubMenu={item.subItems.length > 0} // Indica se o item possui submenus
                isExpanded={expandedMenus[index]} // Passa o estado de expansão
                onClick={() => {
                  if (item.subItems.length > 0) {
                    toggleSubMenu(index)
                  } else {
                    // Navegar apenas se não houver submenus
                    window.location.href = item.to
                  }
                }}
              />
              {expandedMenus[index] && item.subItems.length > 0 && (
                <div style={{ marginLeft: '10px', backgroundColor: '#D1D1D1' }}>
                  {item.subItems.map((subItem, subIndex) => (
                    <MenuCardItem
                      iconName={subItem.iconName}
                      Text={subItem.text}
                      open={open}
                      to={subItem.to}
                      key={subIndex}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </List>

        <Box sx={open ? { overflowY: 'scroll', marginTop: '160px' } : {}}>
          <img
            src={assEngDigital}
            alt='AssEngDigital'
            style={{
              ...styles.logo,
              display: open ? 'block' : 'none',
              paddingBottom: '3%'
            }}
          />
        </Box>
      </Drawer>
    </Box>
  )
}

export default MenuCard
