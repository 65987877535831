// Variável global para rastrear erros na API
let apiError = false

const apiConfig = {
  apiEndpoint:
    process.env.NODE_ENV === 'production'
      ? window.location.origin + '/pattrol-api-components/'
      : 'https://localhost:44339/pattrol-api-components/'
}

export { apiError }

export async function getApi(endpoint, token, baseUrl = null) {
  const url = new URL(
    (baseUrl === null ? apiConfig.apiEndpoint : baseUrl) + endpoint
  )
  const headers = new Headers()
  if (token) {
    headers.append('accessToken', token.accessToken)
    headers.append('userId', token.uniqueId)
  }
  const options = {
    method: 'GET',
    headers: headers
  }
  return fetch(url, options)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      console.error('Error occurred:', error)
      apiError = true // Define a variável como true em caso de erro
      return false
    })
}
export async function getApiBlob(endpoint, token, baseUrl = null) {
  const url = new URL(
    (baseUrl === null ? apiConfig.apiEndpoint : baseUrl) + endpoint
  )
  const headers = new Headers()
  if (token) {
    headers.append('accessToken', token.accessToken)
    headers.append('userId', token.uniqueId)
  }

  const options = {
    method: 'GET',
    headers: headers
  }
  return fetch(url, options)
    .then((response) => response.blob())
    .catch((error) => {
      console.error('Error occurred:', error)
      apiError = true
      return false
    })
}
export async function postApi(endpoint, token, data, baseUrl = null) {
  const url = new URL(
    (baseUrl === null ? apiConfig.apiEndpoint : baseUrl) + endpoint
  )
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  if (token) {
    headers.append('accessToken', token.accessToken)
    headers.append('userId', token.uniqueId)
  }

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return response.json()
    } else {
      return response.ok
    }
  } catch (err) {
    console.error('Fetch Error:', err)
    apiError = true
    return false
  }
}
export async function putApi(endpoint, token, data, baseUrl = null) {
  const url = new URL(
    (baseUrl === null ? apiConfig.apiEndpoint : baseUrl) + endpoint
  )
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  if (token) {
    headers.append('accessToken', token.accessToken)
    headers.append('userId', token.uniqueId)
  }

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  }

  return fetch(url, options)
    .then((response) => response.ok)
    .catch((err) => {
      console.error('Fetch error:', err)
      apiError = true
      return false
    })
}
export async function deleteApi(endpoint, token, baseUrl = null) {
  const url = new URL(
    (baseUrl === null ? apiConfig.apiEndpoint : baseUrl) + endpoint
  )
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  if (token) {
    headers.append('accessToken', token.accessToken)
    headers.append('userId', token.uniqueId)
  }

  const options = {
    method: 'DELETE',
    headers: headers
  }

  return fetch(url, options)
    .then((response) => response.ok)
    .catch((err) => {
      console.error('Fetch error:', err)
      apiError = true
      return false
    })
}

export async function getApiUserPermissions(email, token) {
  return getApi(`userpermission/${email}`, token)
}
