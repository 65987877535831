import React from 'react'
import SectionTitle from './SectionTitle'
import Card from './Card'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import AttachmentIcon from '@mui/icons-material/Attachment'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center' // Centraliza o conteúdo horizontalmente
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  space: {
    height: '46px'
  }
}

function QuickAccessSection() {
  return (
    <div style={styles.container}>
      <SectionTitle title='Acesso rápido' />
      <div style={styles.space} />

      <div style={styles.row}>
        <Card
          label='SharePoint'
          icon={<FolderSharedIcon />}
          link='https://pattrol.sharepoint.com/'
        />
        <Card
          label='Site'
          icon={<AttachmentIcon />}
          link='https://www.pattrol.com.br/'
        />

        <Card
          label='Treinamento'
          icon={<OndemandVideoIcon />}
          link='https://pattrol.sharepoint.com/sites/TodosPattrol/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FTodosPattrol%2FShared%20Documents%2FTreinamentos%20Microsoft%20365&viewid=b45a2ac0%2De300%2D48df%2D9cac%2D7c2bc20a3927&OR=Teams%2DHL&CT=1734614956510&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDExMDExNTcyNCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D'
        />

        <Card
          label='AzureDevops'
          icon={<ContactSupportIcon />}
          link='https://dev.azure.com/pattrol/'
        />
      </div>
      <div style={styles.space} />
    </div>
  )
}

export default QuickAccessSection
