import React from 'react'

import ContactSection from './ContactSection'
import QuickAccessSection from './QuickAccessSection'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    height: '100%'
  }
}

function HomePage() {
  return (
    <div id='HomePage' style={styles.container}>
      <QuickAccessSection />
      <ContactSection />
    </div>
  )
}

export default HomePage
