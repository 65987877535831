import React from 'react'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 60px',
    background: '#E4E4E4',
    borderRadius: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textDecoration: 'none', // Remove sublinhado padrão do link
    cursor: 'pointer', // Mostra um cursor de clique
    color: 'inherit', // Herda a cor do texto
    flex: '1 1 25%' // Cada card ocupa 25% da largura da linha
  },
  icon: {
    fontSize: '40px',
    color: '#000000'
  },
  h1: {
    fontSize: '20px',
    fontFamily: 'RotisSemiSansStd',
    fontWeight: '700',
    color: '#000000',
    padding: '0px',
    margin: '0px',
    whiteSpace: 'nowrap'
  },
  space: {
    height: '8px'
  }
}

function Card({ icon, label, link }) {
  return (
    <a
      href={link}
      style={styles.container}
      target='_blank'
      rel='noopener noreferrer'
    >
      {React.cloneElement(icon, { style: styles.icon })}
      <div style={styles.space} />
      <h1 style={styles.h1}>{label}</h1>
    </a>
  )
}

export default Card
