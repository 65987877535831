import React from 'react'
const styles = {
  container: {
    display: 'flex',
    direction: 'row',
    width: '100%',
    height: 'auto'
  },
  column: {
    flex: '1',
    display: 'flex',
    direction: 'column'
  },
  h3: {
    fontSize: '20px',
    fontFamily: 'RotisSemiSansStd',
    fontWeight: '700',
    color: '#000000'
  }
}

function ContactHeader() {
  return (
    <div style={styles.container}>
      <div style={styles.column}>
        <h3 style={styles.h3}> Setor</h3>
      </div>

      <div style={styles.column}>
        <h3 style={styles.h3}> Telefone</h3>
      </div>

      <div style={styles.column}>
        <h3 style={styles.h3}> Email</h3>
      </div>
    </div>
  )
}

export default ContactHeader
