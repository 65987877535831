// PattrolAuthorization
// Componente INTERNO a esta biblioteca, não deve ser exportado para fora desta biblioteca
// Ele deverá ser o SEGUNDO componente numa "hierarquia" de autenticação e autorização - diretamente subordinado ao componente anterior, Authentication
// O objetivo é verificar a autorização do usuário que já tenha sido autenticado no Microsoft 365
import React, { useEffect, useState } from 'react'
import { Backdrop, CircularProgress, Card, Typography } from '@mui/material'
import {
  getApiUserPermissions,
  getApi,
  getApiBlob,
  postApi,
  putApi,
  deleteApi,
  apiError
} from './apiCall'
import { loginRequest } from './PattrolMsalConfig'
import { PattrolUserContext } from './PattrolUserContext'

import { useIsAuthenticated, useMsal } from '@azure/msal-react'

export function PattrolAuthorization({ children, permissionsAllowed }) {
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState()
  const [token, setToken] = useState()
  const [authorized, setAuthorized] = useState(false)

  // busca credenciais de acesso aa API
  const { instance, accounts } = useMsal()
  const logged = useIsAuthenticated()
  useEffect(() => {
    if (logged) {
      // recupera usuario logado
      setAccount(accounts[0])
      const request = {
        ...loginRequest,
        account: accounts[0]
      }
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setToken(response)
        })
        .catch((e) => {
          instance.acquireTokenRedirect(request).then((response) => {
            setToken(response)
          })
        })
    } else {
      // nenhum usuario logado
      setAccount()
      setToken()
    }
  }, [logged, accounts, instance])

  // sempre que mudar o token, busca permissoes
  useEffect(() => {
    if (account && token) {
      getApiUserPermissions(account.username, token).then((response) => {
        setPermissions(response)
        setLoading(false)
      })
    } else {
      setPermissions([])
    }
  }, [token])

  // sempre que mudar as permissoes, verifica se tem autorizacao
  useEffect(() => {
    setAuthorized(
      permissionsAllowed
        ? permissions
          ? permissions.some((perm) => permissionsAllowed.includes(perm))
          : false
        : true
    )
  }, [permissions])

  return loading ? (
    <Backdrop open>
      <CircularProgress color='inherit' />
    </Backdrop>
  ) : apiError ? (
    <Card
      sx={{
        maxWidth: 500,
        p: 5,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Typography variant='h6' gutterBottom component='div'>
        Erro ao conectar com a API
      </Typography>
      Não foi possível estabelecer uma conexão com o servidor. Por favor, tente
      novamente mais tarde ou entre em contato com o administrador do sistema.
    </Card>
  ) : authorized ? (
    <PattrolUserContext.Provider
      value={{
        account: account,
        token: token,
        permissions: permissions,
        getApi: getApi,
        getApiBlob: getApiBlob,
        postApi: postApi,
        putApi: putApi,
        deleteApi: deleteApi
      }}
    >
      {children}
    </PattrolUserContext.Provider>
  ) : (
    <Card
      sx={{
        maxWidth: 500,
        p: 5,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Typography variant='h6' gutterBottom component='div'>
        Acesso não autorizado
      </Typography>
      Parece que você não tem acesso a essa página. Por favor, verifique com o
      administrador do sistema.
    </Card>
  )
}

export default PattrolAuthorization
