import React from 'react'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    height: '1px',
    width: '100%',
    background: '#E4E4E4'
  },
  h1: {
    fontSize: '16px',
    fontFamily: "'RotisSemiSansStd', sans-serif",
    fontWeight: '400',
    color: '#454545'
  }
}

function ContactLine({ department, phone, email }) {
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.column}>
          <h1 style={styles.h1}> {department}</h1>
        </div>

        <div style={styles.column}>
          <h1 style={styles.h1}> {phone}</h1>
        </div>

        <div style={styles.column}>
          <h1 style={styles.h1}> {email}</h1>
        </div>
      </div>
      <div style={styles.divider} />
    </div>
  )
}

export default ContactLine
