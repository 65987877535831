import React from 'react'

const styles = {
  h1: {
    fontSize: '32px',
    fontFamily: 'RotisSemiSansStd',
    fontWeight: '700',
    color: '#000000',
    whiteSpace: 'nowrap', // Impede quebra de linha no título
    margin: 0 // Remove margens padrões do h1
  },
  divider: {
    height: '1px',
    flexGrow: 1, // Faz o divisor ocupar o espaço restante
    background: '#E4E4E4'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    gap: '24px'
  }
}

function SectionTitle({ title }) {
  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>{title}</h1>
      <div style={styles.divider} />
    </div>
  )
}

export default SectionTitle
