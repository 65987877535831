import ContactHeader from './ContactHeader'
import ContactLine from './ContactLine'
import React from 'react'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}

function ContactTable() {
  return (
    <div style={styles.container}>
      <ContactHeader />

      <ContactLine
        department='RH'
        email='falecom@pattrol.com.br'
        phone='+55 (31) 3995-0256'
      />
      <ContactLine
        department='Engenharia Digital'
        email='engenharia.digital@pattrol.com.br'
      />
    </div>
  )
}

export default ContactTable
